import * as Amplitude from '@amplitude/node';
import IP from "ip";

const client = Amplitude.init("8bd182a3ea1bc69c373c37ad014d4e24");

const amplitude = {
    fireEvent: (req: any) => 
        client.logEvent({
            event_type: req.event_type,
            ip: IP.address(),
            event_properties: req.event_properties
          })
    
};

export { amplitude }