const actionIds = {
  CONFIG_GET: '[CONFIG] GET CONFIG',
  APP_GET_TOP_STORES: '[APP] GET TOP STORES',
  APP_GET_TOP_CATEGORIES: '[APP] GET TOP CATEGORIES',
  APP_GET_USER_DATA: '[APP] GET USER DATA',
  AUTH_EXTERNAL_SIGNIN: '[AUTH] EXTERNAL SIGN IN',
  AUTH_SIGNIN: '[AUTH] SIGN IN',
  AUTH_STORE_USER_IP: '[AUTH] STORE USER IP',
  AUTH_SIGNUP: '[AUTH] SIGN UP',
  AUTH_VERIFY_EMAIL: '[AUTH] VERIFY EMAIL',
  AUTH_RESEND_VERIFY_EMAIL: '[AUTH] RESEND VERIFY EMAIL',
  AUTH_FORGOT_PASSWORD: '[AUTH] FORGOT PASSWORD',
  AUTH_RESET_PASSWORD: '[AUTH] RESET PASSWORD',
  AUTH_RESEND_OTP_BY_EMAIL: '[AUTH] RESEND OTP BY EMAIL',
  ONLINE_CASHBACKS_GET_STORE_DETAILS: '[ONLINE CASHBACKS] GET STORE DETAILS',
  ONLINE_CASHBACKS_GET_MORE_STORES: '[ONLINE CASHBACKS] GET MORE STORES',
  ONLINE_CASHBACKS_GET_STORES_BY_CATEGORY_ID:
    '[ONLINE CASHBACKS] GET STORES BY CATEGORY ID',
  ONLINE_CASHBACKS_GET_MORE_STORES_BY_CATEGORY_ID:
    '[ONLINE CASHBACKS] GET MORE STORES BY CATEGORY ID',
  ONLINE_CASHBACKS_GET_ALL_CATEGORIES: '[ONLINE CASHBACKS] GET ALL CATEGORIES',
  IN_STORE_OFFER_GET_OFFERS: '[IN STORE OFFER] GET OFFERS',
  IN_STORE_OFFER_GET_MORE_OFFERS: '[IN STORE OFFER] GET MORE OFFERS',
  IN_STORE_OFFER_GET_FILTERED_OFFERS: '[IN STORE OFFER] GET FILTERED OFFERS',
  IN_STORE_OFFER_GET_MORE_FILTERED_OFFERS:
    '[IN STORE OFFER] GET MORE FILTERED OFFERS',
  IN_STORE_OFFER_GET_ALL_AREAS: '[IN STORE OFFER] GET ALL AREAS',
  IN_STORE_OFFER_GET_OFFER_BY_ID: '[IN STORE OFFER] GET OFFER BY ID',
  IN_STORE_OFFER_GET_OFFERS_BY_MERCHANT_ID:
    '[IN STORE OFFER] GET OFFERS BY MERCHANT ID',
  CASHOUT_CHECK_MOBILE: '[CASHOUT] CHECK MOBILE',
  CASHOUT_SEND_OTP: '[CASHOUT] SEND OTP',
  CASHOUT_SEND_OTP_MOROCCO: '[CASHOUT] SEND OTP MOROCCO',
  CASHOUT_RESEND_OTP: '[CASHOUT] RESEND OTP',
  CASHOUT_RESEND_OTP_MOROCCO: '[CASHOUT] RESEND OTP MOROCCO',
  CASHOUT_VERIFY_OTP: '[CASHOUT] VERIFY OTP',
  CASHOUT_VERIFY_OTP_MOROCCO: '[CASHOUT] VERIFY OTP MOROCCO',
  CASHOUT_TO_VOUCHER: '[CASHOUT] CASHOUT TO VOUCHER',
  CASHOUT_TO_WAFACASH: '[CASHOUT] CASHOUT TO WAFACASH',
  CASHOUT_BANK_ACCOUNT: '[CASHOUT] CASHOUT TO BANK ACCOUNT',
  CASHOUT_REQUEST: '[CASHOUT] CASHOUT REQUEST',
  WALLET_GET_BALANCE: '[WALLET] GET BALANCE',
  WALLET_GET_TRANSACTIONS: '[WALLET] GET TRANSACTIONS',
  WALLET_GET_MORE_TRANSACTIONS: '[WALLET] GET MORE TRANSACTIONS',
  PROFILE_UPDATE: '[PROFILE] UPDATE PROFILE',
  PROFILE_CHANGE_PASSWORD: '[PROFILE] CHANGE PASSWORD',
  CONTACT_US_GET_SUBJECTS: '[CONTACT US] GET SUBJECTS',
  RESET_SUCCESS: 'RESET SUCCESS',
  NEWSLETTER_SUBSCRIBE: '[NEWSLETTER] SUBSCRIBE',
  NEWSLETTER_UNSUBSCRIBE: '[NEWSLETTER] UNSUBSCRIBE',
  NEWSLETTER_VERIFY_EMAIL: '[NEWSLETTER] VERIFY EMAIL',
};

export default actionIds;
