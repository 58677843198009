import actionCreatorFactory from "typescript-fsa";

import { newsletterApi } from "../api/newsletter";
import { asyncAction } from "../helpers";
import actionIds from "./action-ids";

const actionCreator = actionCreatorFactory();

const newsletterSubscribe = actionCreator.async<any, string[], any>(
  actionIds.NEWSLETTER_SUBSCRIBE
);

const newsletterUnsubscribe = actionCreator.async<any, string[], any>(
  actionIds.NEWSLETTER_UNSUBSCRIBE
);

const newsletterVerifyEmail = actionCreator.async<any, string[], any>(
  actionIds.NEWSLETTER_VERIFY_EMAIL
);



const newsletterSubscribeAsync = asyncAction(newsletterSubscribe, newsletterApi.subscribeToNewsLetter);
const newsletterUnsubscribeAsync = asyncAction(newsletterSubscribe, newsletterApi.unsubscribeFromNewsLetter);
const newsletterVerifyEmailAsync = asyncAction(newsletterVerifyEmail, newsletterApi.validateNewsLetterSubscription);

export {
  newsletterSubscribeAsync,
  newsletterSubscribe,
  newsletterUnsubscribeAsync,
  newsletterUnsubscribe,
  newsletterVerifyEmailAsync,
  newsletterVerifyEmail
};
