import { AppConfig } from '../config/config';

import axios from 'axios';
import { Cookies } from 'react-cookie';

const token = localStorage.getItem('Token');
const lang = localStorage.getItem('i18nextLng');
const currentLang = AppConfig.get('LANGUAGES').find(el => el.code === lang);

const renderBase = axios.create({
  baseURL: `${window.env.REACT_APP_MS_GATEWAY}`,
  headers: {
    common: {
      'Accept-Language': currentLang?.code || AppConfig.get('DEFAULT_LANG'),
      'x-accept-Language': currentLang?.code || AppConfig.get('DEFAULT_LANG'),
      'x-accept-country': AppConfig.get('COUNTRY'),
      'x-api-key': window.env.REACT_APP_X_API_KEY,
      'x-api-key-type': window.env.REACT_APP_X_API_KEY_TYPE,
    },
  },
});

renderBase.interceptors.request.use(request => {
  if (token) request.headers['Authorization'] = 'Bearer ' + token;
  return request;
});
renderBase.interceptors.response.use(response => {
  if (response.status === 401) {
    localStorage.clear();
    new Cookies().remove('token');
    window.location.assign('/SignIn');
  }
  return response;
});

export default renderBase;
