import actionCreatorFactory from 'typescript-fsa';

import { cashoutApi } from '../api/cashout';
import { asyncAction } from '../helpers';
import actionIds from './action-ids';

const actionCreator = actionCreatorFactory();

const createCashoutRequestAction = actionCreator.async<any, any, string[]>(
  actionIds.CASHOUT_REQUEST,
);

const createCashoutRequestAsync = asyncAction(
  createCashoutRequestAction,
  cashoutApi.createCashout,
);

export { createCashoutRequestAsync, createCashoutRequestAction };
