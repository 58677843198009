import actionCreatorFactory from 'typescript-fsa';

import { accountApi } from '../api/account';
import { asyncAction } from '../helpers';
import actionIds from './action-ids';

const actionCreator = actionCreatorFactory();

const checkMobileAction = actionCreator.async<any, string[], any>(
  actionIds.CASHOUT_CHECK_MOBILE,
);

const sendOTPAction = actionCreator.async<any, string[], any>(
  actionIds.CASHOUT_SEND_OTP,
);

const sendOTPMoroccoAction = actionCreator.async<any, string[], any>(
  actionIds.CASHOUT_SEND_OTP_MOROCCO,
);

const resendOTPAction = actionCreator.async<any, string[], any>(
  actionIds.CASHOUT_RESEND_OTP,
);

const resendOTPMoroccoAction = actionCreator.async<any, string[], any>(
  actionIds.CASHOUT_RESEND_OTP_MOROCCO,
);

const verifyOTPAction = actionCreator.async<any, string[], any>(
  actionIds.CASHOUT_VERIFY_OTP,
);

const verifyOTPMoroccoAction = actionCreator.async<any, string[], any>(
  actionIds.CASHOUT_VERIFY_OTP_MOROCCO,
);

const updateProfileAction = actionCreator.async<any, string[], any>(
  actionIds.PROFILE_UPDATE,
);

const changePasswordAction = actionCreator.async<any, string[], any>(
  actionIds.PROFILE_CHANGE_PASSWORD,
);

const sendOTPMoroccoAsync = asyncAction(
  sendOTPMoroccoAction,
  accountApi.sendOTPMorocco,
);

const resendOTPMoroccoAsync = asyncAction(
  resendOTPMoroccoAction,
  accountApi.resendOTPMorocco,
);

const verifyOTPMoroccoAsync = asyncAction(
  verifyOTPMoroccoAction,
  accountApi.verifyOTPMorocco,
);

const updateProfileAsync = asyncAction(
  updateProfileAction,
  accountApi.UpdateProfile,
);

const changePasswordAsync = asyncAction(
  changePasswordAction,
  accountApi.ChangePassword,
);

export {
  checkMobileAction,
  sendOTPAction,
  sendOTPMoroccoAction,
  sendOTPMoroccoAsync,
  resendOTPAction,
  resendOTPMoroccoAction,
  resendOTPMoroccoAsync,
  verifyOTPAction,
  verifyOTPMoroccoAction,
  verifyOTPMoroccoAsync,
  updateProfileAction,
  updateProfileAsync,
  changePasswordAction,
  changePasswordAsync,
};
