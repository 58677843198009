class UTMTracker {
  static parse(searchString: String) {
    if (searchString !== "") {
      const query = searchString.substr(1).split("&");
      query.forEach((pair) => {
        const [key, value] = pair.split("=");
        if (key === "utm_campaign") {
          localStorage.setItem("influencer", value);
        }
      });
    }
  }

  static getCode() {
    return localStorage.getItem("influencer");
  }

  static clearCode() {
    return localStorage.removeItem("influencer");
  }
}

export default UTMTracker;
