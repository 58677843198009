import { AppConfig } from '../config/config';
import renderBase from './renderBase';

class appApi {
  static getUserData() {
    return renderBase.get(`/api/v1/wcb-render/oauth/me`).then(res => {
      return res.data;
    });
  }

  static async getConfigs() {
    const res = await renderBase.get(`/api/v1/wcb-render/configurations`);
    return res.data;
  }
}

export { appApi };
