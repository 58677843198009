import renderBase from './renderBase';

const onlineCashbacksApi = {
  getStoreDetails: (id: any) =>
    renderBase
      .get(`/api/v1/wcb-render/pages/merchants/${id}/redirect`)
      .then(res => {
        return res.data;
      }),
};

export { onlineCashbacksApi };
