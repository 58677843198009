import { AnyAction, isType } from "typescript-fsa";

import {
  newsletterSubscribe,
  newsletterVerifyEmail,
  newsletterUnsubscribe,
} from "../actions";
import { TNewsletterState, ResponseModel } from "../types";

const initialState: TNewsletterState = {
  error: null,
  success: null,
};

const newsletterReducer = (
  state: TNewsletterState = initialState,
  action: AnyAction
): TNewsletterState => {
  if (isType(action, newsletterSubscribe.started)) {
    return {
      ...state,
      error: null,
      success: null,
    };
  }

  if (isType(action, newsletterSubscribe.done)) {
    const response = (action.payload.result as unknown) as ResponseModel;
    if (response.Status === 200) {
      return {
        ...state,
        error: null,
        success: "Your email was successfully added",
      };
    } else {
      return {
        ...state,
        error: "Please enter a valid email",
        success: null,
      };
    }
  }

  if (isType(action, newsletterSubscribe.failed)) {
    const error = action.payload.error.response.data;
    let message = "We could not register your email, please try again";
    if (error.Status === 400) {
      message = "This email is already registered";
    }
    return {
      ...state,
      error: message,
      success: null,
    };
  }

  if (isType(action, newsletterUnsubscribe.started)) {
    return {
      ...state,
      error: null,
      success: null,
    };
  }

  if (isType(action, newsletterUnsubscribe.done)) {
    const response = (action.payload.result as unknown) as ResponseModel;
    if (response.Status === 200) {
      return {
        ...state,
        error: null,
        success: "You have successfully unsubscribed from newsletter",
      };
    } else {
      return {
        ...state,
        error: "Please try agaon later",
        success: null,
      };
    }
  }

  if (isType(action, newsletterVerifyEmail.started)) {
    return {
      ...state,
      error: null,
      success: null,
    };
  }

  if (isType(action, newsletterVerifyEmail.done)) {
    const response = (action.payload.result as unknown) as ResponseModel;
    if (response.Status === 200) {
      return {
        ...state,
        error: null,
        success: "Your email was successfully added",
      };
    } else {
      return {
        ...state,
        error: "Please enter a valid email",
        success: null,
      };
    }
  }
  return state;
};

export { newsletterReducer };
