import { AppConfig } from '../../config/config';
import { amplitude } from './amplitudeJS';

const SourceRedirection = (referer: string, path: string) => {
  if (referer.includes('facebook')) {
    amplitude.fireEvent({
      event_type: 'discover_landing_page',
      event_properties: localStorage.getItem('Token')
        ? {
            login_method: localStorage.getItem('loginMethod'),
            user_id: localStorage.getItem('Id'),
            Login_status: 'Logged In',
            Page: path,
            camp_source: 'facebook',
            user_country: AppConfig.get('COUNTRY'),
          }
        : {
            Login_status: 'Anonymous',
            Page: path,
            camp_source: 'facebook',
            user_country: AppConfig.get('COUNTRY'),
          },
    });
  } else if (referer.includes('instagram')) {
    amplitude.fireEvent({
      event_type: 'discover_landing_page',
      event_properties: localStorage.getItem('Token')
        ? {
            login_method: localStorage.getItem('loginMethod'),
            user_id: localStorage.getItem('Id'),
            Login_status: 'Logged In',
            Home_View: path,
            camp_source: 'instagram',
            user_country: AppConfig.get('COUNTRY'),
          }
        : {
            Login_status: 'Anonymous',
            Home_View: path,
            camp_source: 'instagram',
            user_country: AppConfig.get('COUNTRY'),
          },
    });
  } else if (referer.includes('google')) {
    amplitude.fireEvent({
      event_type: 'discover_landing_page',
      event_properties: localStorage.getItem('Token')
        ? {
            login_method: localStorage.getItem('loginMethod'),
            user_id: localStorage.getItem('Id'),
            Login_status: 'Logged In',
            Home_View: path,
            camp_source: 'google',
            user_country: AppConfig.get('COUNTRY'),
          }
        : {
            Login_status: 'Anonymous',
            Home_View: path,
            camp_source: 'google',
            user_country: AppConfig.get('COUNTRY'),
          },
    });
  }
};

export { SourceRedirection };
