import renderBase from './renderBase';

import { CashoutModel } from '../types';

const cashoutApi = {
  createCashout: (req: CashoutModel) =>
    renderBase
      .post(`/api/v1/wcb-render/wallet/cashout`, {
        amount: +req.amount,
        methodId: req.methodId,
        details: req.details,
      })
      .then(res => res),
};

export { cashoutApi };
