import actionCreatorFactory from 'typescript-fsa';

import { appApi } from '../api/app';
import { asyncAction } from '../helpers';
import actionIds from './action-ids';
import { TopStoresRequestModel } from '../types';

const actionCreator = actionCreatorFactory();

const getTopStoresAction = actionCreator.async<
  TopStoresRequestModel,
  string[],
  any
>(actionIds.APP_GET_TOP_STORES);

const getTopCategoriesAction = actionCreator.async<undefined, string[], any>(
  actionIds.APP_GET_TOP_CATEGORIES,
);

const getUserDataAction = actionCreator.async<any, any, any>(
  actionIds.APP_GET_USER_DATA,
);

const getUserDataAsync = asyncAction(getUserDataAction, appApi.getUserData);

export {
  getTopStoresAction,
  getTopCategoriesAction,
  getUserDataAction,
  getUserDataAsync,
};
