import renderBase from './renderBase';
import UTMTracker from '../helpers/shared/utm-tracker';
import { ResetPasswordModel } from '../types';

const authApi = {
  extrnalSignIn: (req: any) =>
    renderBase
      .post('/api/v1/wcb-render/oauth/social-login', {
        ...req.user,
        utmCode: UTMTracker.getCode(),
      })
      .then(res => {
        // if register was a success then remove the utm code so it will not be used a second time
        UTMTracker.clearCode();

        res.data.Source = req.source;
        res.data.LoginMethod = req.loginMethod;
        return res.data;
      }),
  SignUp: (req: any) =>
    renderBase
      .post('/api/v1/wcb-render/oauth/register', {
        ...req.user,
        utmCode: UTMTracker.getCode(),
      })
      .then(res => {
        // if register was a success then remove the utm code so it will not be used a second time
        UTMTracker.clearCode();
        return res.data;
      }),

  SignIn: (req: any) =>
    renderBase
      .post('/api/v1/wcb-render/oauth/login', {
        email: req.user.Email,
        password: req.user.Password,
        captcha: req.user.securityToken,
      })
      .then(res => {
        res.data.Source = req.source;
        if (res.status === 307) {
          res.data.needResetPassword = true;
        }
        return res.data;
      }),
  verifyEmail: (req: any) =>
    renderBase
      .post('/api/v1/wcb-render/oauth/register/confirm', {
        token: req.token,
        email: req.email,
      })
      .then(res => {
        return res.data;
      }),

  forgotPassword: (email: string) =>
    renderBase
      .post(`/api/v1/wcb-render/oauth/forgot-password`, {
        email: email,
      })
      .then(res => {
        return res.data;
      }),
  resetPassword: (req: ResetPasswordModel) =>
    renderBase
      .put('/api/v1/wcb-render/oauth/reset-password', {
        newPassword: req.newPassword,
        confirmPassword: req.confirmPassword,
        token: req.token,
        email: req.email,
      })
      .then(res => {
        return res.data;
      }),
};

export { authApi };
