import React, * as react from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

import { LastLocationProvider } from "react-router-last-location";
import UTMTracker from "../helpers/shared/utm-tracker";


import Loader from "./shared/Loader/Loader";

const App = react.lazy(() => import("./App/App"));
// const Home = react.lazy(() => import("./Home/Home"));
const OnlineCashbacks = react.lazy(
  () => import("./OnlineCashbacks/OnlineCashbacks")
);
const SignIn = react.lazy(() => import("./SignIn/SignIn"));
const SignUp = react.lazy(() => import("./SignUp/SignUp"));
const Profile = react.lazy(() => import("./Profile/Profile"));
const EditPassword = react.lazy(() => import("./Profile/EditPassword"));
const ContactUs = react.lazy(() => import("./ContactUs/ContactUs"));
const PrivacyPolicy = react.lazy(() => import("./PrivacyPolicy/PrivacyPolicy"));
const FAQs = react.lazy(() => import("./FAQs/FAQs"));
const AboutUs = react.lazy(() => import("./AboutUs/AboutUs"));
const Wallet = react.lazy(() => import("./Wallet/Wallet"));
const Error404 = react.lazy(() => import("./ErrorPages/Error404"));
const ErrorNoConnection = react.lazy(
  () => import("./ErrorPages/ErrorNoConnection")
);

const Cashout = react.lazy(() => import("./Cashout/Cashout"));
const MergeWithApp = react.lazy(() => import("./MergeWithApp/MergeWithApp"));
const CashoutErrorModal = react.lazy(
  () => import("./Cashout/CashoutErrorModal/CashoutErrorModal")
);
const MailVerification = react.lazy(
  () => import("./MailVerification/MailVerification")
);
const ForgotPassword = react.lazy(
  () => import("./ForgotPassword/ForgotPassword")
);
const MailVerified = react.lazy(
  () => import("./MailVerification/MailVerified")
);
const NewsletterEmailVerify = react.lazy(
  () => import("./Newsletter/NewsletterEmailVerify")
);
const UnsubscribeFromNewsletter = react.lazy(
  () => import("./Newsletter/UnsubscribeFromNewsletter")
);
const ResetPasswordVerification = react.lazy(
  () => import("./ForgotPassword/ResetPasswordVerification")
);
const CashbackStoreDetails = react.lazy(
  () => import("./OnlineCashbacks/CashbackStoreDetails")
);
const InStoreOffers = react.lazy(() => import("./InStoreOffers/InStoreOffers"));
const InStoreOfferDetails = react.lazy(
  () => import("./InStoreOffers/InStoreOfferDetails")
);
const RedirectionBanner = react.lazy(
  () => import("./OnlineCashbacks/RedirectionBanner")
);

const OfferBanner = react.lazy(
  () => import("./InStoreOffers/StoreOfferDetails/OfferBanner/OfferBanner")
);
const NewPassword = react.lazy(() => import("./ForgotPassword/NewPassword"));

const TermsAndConditions = react.lazy(
  () => import("./TermsAndConditions/TermsAndConditions")
);



const Routing = () => (
  <Router>
    <LastLocationProvider>
      <react.Suspense fallback={<Loader />}>
        <RoutingComponents />
      </react.Suspense>
    </LastLocationProvider>
  </Router>
);

const RoutingComponents = () => {
  const location = useLocation();
  UTMTracker.parse(location.search);

  return (
    <Switch>
      <Route exact path="/Home" component={App} />
      {/* <Route exact path="/Main" component={Home} /> */}
      <Route path="/OnlineCashbacks" component={OnlineCashbacks} />
      <UnProtectedRoute path="/SignIn" component={SignIn} />
      <UnProtectedRoute path="/SignUp" component={SignUp} />
      <UnProtectedRoute
        path="/MailVerification/:email"
        component={MailVerification}
      />
      <UnProtectedRoute
        path="/MailVerified"
        component={MailVerified}
      />
      <Route
        path="/SubscribeToNewsletter/:token/:lang"
        component={NewsletterEmailVerify}
      />
      <Route
        path="/UnsubscribeFromNewsletter/:email"
        component={UnsubscribeFromNewsletter}
      />
      <Route path="/ForgotPassword" component={ForgotPassword} />
      <Route path="/NewPassword" component={NewPassword} />
      <ProtectedRoute path="/Wallet" component={Wallet} />
      <ProtectedRoute path="/Cashout" component={Cashout} />
      <ProtectedRoute path="/MergeWithApp" component={MergeWithApp} />
      <ProtectedRoute path="/Profile" component={Profile} />
      <ProtectedRoute path="/EditPassword" component={EditPassword} />
      <Route path="/ContactUs" component={ContactUs} />
      <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
      <Route path="/TermsAndConditions" component={TermsAndConditions} />
      <Route path="/FAQs" component={FAQs} />
      <Route path="/AboutUs" component={AboutUs} />
      <Route path="/Error404" component={Error404} />
      <Route path="/ErrorNoConnection" component={ErrorNoConnection} />
      <Route path="/CashoutErrorModal" component={CashoutErrorModal} />
      <Route path="/OfferBanner" component={OfferBanner} />
      <Route
        path="/ResetPasswordVerification/:email"
        component={ResetPasswordVerification}
      />

      <Route path="/InStoreOffers" component={InStoreOffers} />
      <Route path="/InStoreOfferDetails/:id" component={InStoreOfferDetails} />
      <Route
        path="/CashbackStoreDetails/:id"
        component={CashbackStoreDetails}
      />
      <Route path="/RedirectionBanner/:storeId" component={RedirectionBanner} />
      <Redirect to="/Home" />
    </Switch>
  );
};

const ProtectedRoute = ({ component: Comp, path, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return localStorage.getItem("Token") ? (
          <Comp {...props} />
        ) : (
          <Redirect to="/SignIn" />
        );
      }}
    />
  );
};

const UnProtectedRoute = ({ component: Comp, path, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return !localStorage.getItem("Token") ? (
          <Comp {...props} />
        ) : (
          <Redirect to="/Home" />
        );
      }}
    />
  );
};

export default Routing;
