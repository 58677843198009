import Amplitude from "amplitude-js";

//production ae263b23fd5d4d8849386fb327a376b8
//staging 8bd182a3ea1bc69c373c37ad014d4e24

Amplitude.getInstance().init("ae263b23fd5d4d8849386fb327a376b8");

const amplitude = {
  fireEvent: req =>
    Amplitude.getInstance().logEvent(req.event_type, req.event_properties)
};

export { amplitude };
