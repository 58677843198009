import TagManager from 'react-gtm-module';

class GTMTracker {
  static initialize() {
    const tagManagerArgs = {
      gtmId: window.env.REACT_APP_GTM_ID || 'GTM-T47X7B3',
    };

    TagManager.initialize(tagManagerArgs);
  }

  static sendEvent(event: any) {
    TagManager.dataLayer({
      dataLayer: event,
    });
  }
}

export default GTMTracker;
