import { AnyAction, isType } from 'typescript-fsa';

import {
  extrnalSignInAction,
  SignUpAction,
  VerifyEmailAction,
  SignInAction,
  ResetPasswordAction,
  ForgotPasswordAction,
  ResetSuccessAction,
  ResendVerifyEmailAction,
} from '../actions';
import { TUserState, UserModel, ResponseModel } from '../types';
//import { amplitude } from "../helpers";
import { amplitude } from '../helpers/shared/amplitudeJS';
import GTMTracker from '../helpers/shared/gtm-tracker';
import { AppConfig } from '../config/config';

const initialState: TUserState = {
  User: {} as UserModel,
  isFetching: false,
  signInError: '',
  signUpError: '',
  ForgetPasswordError: '',
  success: '',
  errorCode: null,
  needResetPassword: false,
};

const authReducer = (
  state: TUserState = initialState,
  action: AnyAction,
): TUserState => {
  if (isType(action, extrnalSignInAction.started)) {
    return {
      ...state,
      isFetching: true,
      signInError: '',
      signUpError: '',
      ForgetPasswordError: '',
      success: '',
      errorCode: null,
    };
  }

  if (isType(action, extrnalSignInAction.done)) {
    const response = action.payload.result as unknown as ResponseModel;
    const responseData = response.profile as UserModel;
    document.cookie = `token=${response.accessToken}`;
    localStorage.setItem('Id', responseData.id || '');
    localStorage.setItem('Token', response.accessToken || '');
    localStorage.setItem('Name', responseData.fullName || '');
    localStorage.setItem('Email', responseData.email || '');
    localStorage.setItem('Phone', responseData.phone || '');
    localStorage.setItem(
      'IsMerged',
      responseData.IsMerged?.toString() || 'false',
    );
    localStorage.setItem('ReferralCode', responseData.ReferralCode || '');

    // GTM for complete login
    GTMTracker.sendEvent({
      event: 'login_complete_login',
      event_properties: {
        source: response.Source,
        flow: 'signin',
        login_method: response.LoginMethod,
        User_id: localStorage.getItem('Id'),
      },
    });

    amplitude.fireEvent({
      event_type: 'login_complete_login',
      event_properties: {
        source: response.Source,
        flow: 'signin',
        login_method: response.LoginMethod,
        User_id: localStorage.getItem('Id'),
        user_country: AppConfig.get('COUNTRY'),
      },
    });

    return {
      ...state,
      User: responseData,
      isFetching: false,
      signInError: '',
      signUpError: '',
      ForgetPasswordError: '',
      success: 'Y',
      errorCode: null,
    };
  }

  if (isType(action, extrnalSignInAction.failed)) {
    let errorMsg;
    let errorCodeResponse;
    if (action.payload.error.response) {
      errorMsg = action.payload.error.response.data.message;
      if (typeof errorMsg === 'string') {
        errorMsg = [errorMsg];
      }
      errorCodeResponse = action.payload.error.response.status;

      amplitude.fireEvent({
        event_type: 'login_API_Failure',
        event_properties: {
          error_description: errorMsg.join(','),
          email: action.payload.params.user.Email,
          login_method: action.payload.params.loginMethod,
          user_country: AppConfig.get('COUNTRY'),
        },
      });
    } else {
      amplitude.fireEvent({
        event_type: 'login_API_Failure',
        event_properties: {
          error_description: 'Network Error',
          email: action.payload.params.user.Email,
          login_method: action.payload.params.loginMethod,
          user_country: AppConfig.get('COUNTRY'),
        },
      });
      window.location.assign(`${process.env.PUBLIC_URL}/ErrorNoConnection`);
    }

    return {
      ...state,
      isFetching: false,
      signInError: errorMsg.join(','),
      success: 'N',
      signUpError: '',
      ForgetPasswordError: '',
      errorCode: errorCodeResponse,
    };
  }

  if (isType(action, SignUpAction.started)) {
    return {
      ...state,
      isFetching: true,
      signInError: '',
      signUpError: '',
      ForgetPasswordError: '',
      success: '',
      errorCode: null,
    };
  }

  if (isType(action, SignUpAction.done)) {
    const response = action.payload.result as unknown as ResponseModel;
    // GTM for complete login
    GTMTracker.sendEvent({
      event: 'login_complete_login',
      event_properties: {
        source: response.Source,
        flow: 'signup',
        login_method: 'own email',
        User_id: localStorage.getItem('Id'),
      },
    });

    amplitude.fireEvent({
      event_type: 'login_complete_login',
      event_properties: {
        source: response.Source,
        flow: 'signup',
        login_method: 'own email',
        User_id: localStorage.getItem('Id'),
        user_country: AppConfig.get('COUNTRY'),
      },
    });

    return {
      ...state,
      isFetching: false,
      signInError: '',
      signUpError: '',
      ForgetPasswordError: '',
      success: 'YA',
      errorCode: null,
    };
  }

  if (isType(action, SignUpAction.failed)) {
    let errorMsg;
    let errorCodeResponse;
    if (action.payload.error.response) {
      errorMsg = action.payload.error.response.data.message;
      errorCodeResponse = action.payload.error.response.data.message;
    } else {
      window.location.assign(`${process.env.PUBLIC_URL}/ErrorNoConnection`);
    }
    return {
      ...state,
      isFetching: false,
      signUpError: errorMsg,
      signInError: '',
      ForgetPasswordError: '',
      success: 'N',
      errorCode: errorCodeResponse,
    };
  }

  if (isType(action, VerifyEmailAction.started)) {
    return {
      ...state,
      isFetching: true,
      signInError: '',
      signUpError: '',
      ForgetPasswordError: '',
      errorCode: null,
    };
  }

  if (isType(action, VerifyEmailAction.done)) {
    return {
      ...state,
      isFetching: false,
      signInError: '',
      signUpError: '',
      ForgetPasswordError: '',
      errorCode: null,
    };
  }

  if (isType(action, VerifyEmailAction.failed)) {
    return {
      ...state,
      isFetching: false,
      signInError: '',
      signUpError: '',
      ForgetPasswordError: '',
      errorCode: null,
    };
  }

  if (isType(action, ResendVerifyEmailAction.started)) {
    return {
      ...state,
      isFetching: true,
      signInError: '',
      signUpError: '',
      ForgetPasswordError: '',
      errorCode: null,
    };
  }

  if (isType(action, ResendVerifyEmailAction.done)) {
    return {
      ...state,
      isFetching: false,
      signInError: '',
      signUpError: '',
      ForgetPasswordError: '',
      errorCode: null,
    };
  }

  if (isType(action, ResendVerifyEmailAction.failed)) {
    return {
      ...state,
      isFetching: false,
      signInError: '',
      signUpError: '',
      ForgetPasswordError: '',
      errorCode: null,
    };
  }

  if (isType(action, SignInAction.started)) {
    return {
      ...state,
      isFetching: true,
      signInError: '',
      signUpError: '',
      ForgetPasswordError: '',
      success: '',
      errorCode: null,
    };
  }

  if (isType(action, SignInAction.done)) {
    const response = action.payload.result as unknown as ResponseModel;
    const responseData = response.profile as UserModel;
    document.cookie = `token=${response.accessToken}`;
    localStorage.setItem('Id', responseData.id || '');
    localStorage.setItem('Token', response.accessToken || '');
    localStorage.setItem('Name', responseData.fullName || '');
    localStorage.setItem('Email', responseData.email || '');
    localStorage.setItem('Phone', responseData.phone || '');
    localStorage.setItem('AffiliateUserId', responseData.AffiliateUserId || '');
    localStorage.setItem('loginMethod', 'own email');
    localStorage.setItem(
      'IsMerged',
      responseData.IsMerged?.toString() || 'false',
    );
    localStorage.setItem('ReferralCode', responseData.ReferralCode || '');

    // GTM for complete login
    GTMTracker.sendEvent({
      event: 'login_complete_login',
      event_properties: {
        source: response.Source,
        flow: 'signin',
        login_method: localStorage.getItem('loginMethod'),
        User_id: localStorage.getItem('Id'),
      },
    });

    amplitude.fireEvent({
      event_type: 'login_complete_login',
      event_properties: {
        source: response.Source,
        flow: 'signin',
        login_method: localStorage.getItem('loginMethod'),
        User_id: localStorage.getItem('Id'),
        user_country: AppConfig.get('COUNTRY'),
      },
    });
    return {
      ...state,
      User: responseData,
      isFetching: false,
      signInError: '',
      signUpError: '',
      ForgetPasswordError: '',
      success: 'Y',
      errorCode: null,
    };
  }

  if (isType(action, SignInAction.failed)) {
    console.log('falied');
    let errorMsg;
    let errorCodeResponse;
    let needResetPassword;
    if (action.payload.error.response) {
      errorMsg = action.payload.error.response.data.message;
      errorCodeResponse = action.payload.error.response.data.message;
      needResetPassword =
        action.payload.error.response.data.statusCode === 307 ? true : false;
      amplitude.fireEvent({
        event_type: 'registration_API_Failure',
        event_properties: {
          error_description: errorMsg,
          email: action.payload.params.user.Email,
          login_method: 'own email',
          user_country: AppConfig.get('COUNTRY'),
        },
      });
    } else {
      amplitude.fireEvent({
        event_type: 'registration_API_Failure',
        event_properties: {
          error_description: 'Network Error',
          email: action.payload.params.user.Email,
          login_method: 'own email',
          user_country: AppConfig.get('COUNTRY'),
        },
      });
      window.location.assign(`${process.env.PUBLIC_URL}/ErrorNoConnection`);
    }
    return {
      ...state,
      isFetching: false,
      signInError: errorMsg,
      signUpError: '',
      ForgetPasswordError: '',
      success: 'N',
      errorCode: errorCodeResponse,
      needResetPassword: needResetPassword,
    };
  }

  if (isType(action, ForgotPasswordAction.started)) {
    return {
      ...state,
      isFetching: true,
      signUpError: '',
      ForgetPasswordError: '',
      signInError: '',
      success: '',
      errorCode: null,
    };
  }

  if (isType(action, ForgotPasswordAction.done)) {
    return {
      ...state,
      isFetching: false,
      signUpError: '',
      ForgetPasswordError: '',
      signInError: '',
      success: 'Y',
      errorCode: null,
    };
  }

  if (isType(action, ForgotPasswordAction.failed)) {
    let errorMsg;
    let errorCodeResponse;
    if (action.payload.error.response) {
      errorCodeResponse = action.payload.error.response.statusText;
      errorMsg = action.payload.error.response.data.message;
    } else {
      window.location.assign(`${process.env.PUBLIC_URL}/ErrorNoConnection`);
    }
    return {
      ...state,
      isFetching: false,
      ForgetPasswordError: errorMsg,
      signUpError: '',
      signInError: '',
      success: 'N',
      errorCode: errorCodeResponse,
    };
  }

  if (isType(action, ResetPasswordAction.started)) {
    return {
      ...state,
      isFetching: true,
      signUpError: '',
      ForgetPasswordError: '',
      signInError: '',
      success: '',
      errorCode: null,
    };
  }

  if (isType(action, ResetPasswordAction.done)) {
    return {
      ...state,
      isFetching: false,
      signUpError: '',
      ForgetPasswordError: '',
      signInError: '',
      success: 'Y',
      errorCode: null,
    };
  }

  if (isType(action, ResetPasswordAction.failed)) {
    let errorMsg;
    if (action.payload.error.response) {
      errorMsg = action.payload.error.response.data.message;
    } else {
      window.location.assign(`${process.env.PUBLIC_URL}/ErrorNoConnection`);
    }
    return {
      ...state,
      isFetching: false,
      ForgetPasswordError: errorMsg,
      signUpError: '',
      signInError: '',
      success: 'N',
      errorCode: null,
    };
  }

  if (isType(action, ResetSuccessAction)) {
    return {
      ...state,
      isFetching: false,
      ForgetPasswordError: '',
      signUpError: '',
      signInError: '',
      success: '',
      errorCode: null,
    };
  }

  return state;
};

export { authReducer };
