import { AnyAction, isType } from 'typescript-fsa';

import { createCashoutRequestAction } from '../actions';
import { ResponseModel, TCashoutState } from '../types';

const initialState: TCashoutState = {
  isLoading: false,
};

const cashoutReducer = (
  state: TCashoutState = initialState,
  action: AnyAction,
): TCashoutState => {
  if (isType(action, createCashoutRequestAction.started)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (isType(action, createCashoutRequestAction.done)) {
    const response = action.payload.result as unknown as ResponseModel;
    if (response.callback) response.callback();
    return {
      ...state,
      isLoading: false,
    };
  }

  if (isType(action, createCashoutRequestAction.failed)) {
    // Do error handling work if needed
    return {
      ...state,
      isLoading: false,
    };
  }

  return state;
};

export { cashoutReducer };
