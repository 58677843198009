import renderBase from './renderBase';

const newsletterApi = {
  subscribeToNewsLetter: (email: string) =>
    renderBase
      .put('/api/v1/wcb-render/actions/newsletter/subscribe', {
        email: email,
      })
      .then(res => res.data),
  validateNewsLetterSubscription: (token: string) =>
    renderBase
      .put('/api/v1/wcb-render/actions/newsletter/verify', {
        token: token,
      })
      .then(res => res.data),
  unsubscribeFromNewsLetter: (email: string) =>
    renderBase
      .put('/api/v1/wcb-render/actions/newsletter/unsubscribe', {
        email: email,
      })
      .then(res => res.data),
};

export { newsletterApi };
