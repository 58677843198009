import actionCreatorFactory from 'typescript-fsa';
import { authApi } from '../api/auth';
import { asyncAction } from '../helpers';
import actionIds from './action-ids';
import { ResetPasswordModel } from '../types';

const actionCreator = actionCreatorFactory();

const extrnalSignInAction = actionCreator.async<any, string[], any>(
  actionIds.AUTH_EXTERNAL_SIGNIN,
);

const SignUpAction = actionCreator.async<any, string[], any>(
  actionIds.AUTH_SIGNUP,
);

const VerifyEmailAction = actionCreator.async<any, string[], any>(
  actionIds.AUTH_VERIFY_EMAIL,
);

const ResendVerifyEmailAction = actionCreator.async<any, string[], any>(
  actionIds.AUTH_RESEND_VERIFY_EMAIL,
);

const SignInAction = actionCreator.async<any, string[], any>(
  actionIds.AUTH_SIGNIN,
);

const ForgotPasswordAction = actionCreator.async<string, string[], any>(
  actionIds.AUTH_FORGOT_PASSWORD,
);

const ResendOTPByEmailAction = actionCreator.async<any, string[], any>(
  actionIds.AUTH_RESEND_OTP_BY_EMAIL,
);

const ResetPasswordAction = actionCreator.async<
  ResetPasswordModel,
  string[],
  any
>(actionIds.AUTH_RESET_PASSWORD);

const ResetSuccessAction = actionCreator(actionIds.RESET_SUCCESS);

const extrnalSignInAsync = asyncAction(
  extrnalSignInAction,
  authApi.extrnalSignIn,
);

const SignInAsync = asyncAction(SignInAction, authApi.SignIn);

const signUpAsync = asyncAction(SignUpAction, authApi.SignUp);

const VerifyEmailAsync = asyncAction(VerifyEmailAction, authApi.verifyEmail);

const ForgotPasswordAsync = asyncAction(
  ForgotPasswordAction,
  authApi.forgotPassword,
);

const ResetPasswordAsync = asyncAction(
  ResetPasswordAction,
  authApi.resetPassword,
);

export {
  extrnalSignInAction,
  extrnalSignInAsync,
  SignUpAction,
  signUpAsync,
  VerifyEmailAction,
  VerifyEmailAsync,
  SignInAction,
  SignInAsync,
  ForgotPasswordAction,
  ForgotPasswordAsync,
  ResendOTPByEmailAction,
  ResetPasswordAction,
  ResetPasswordAsync,
  ResetSuccessAction,
  ResendVerifyEmailAction,
};
