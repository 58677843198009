import renderBase from './renderBase';

const walletApi = {
  get: () => renderBase.get(`/api/v1/wcb-render/wallet`).then(res => res.data),
  getTransactions: (page: number) =>
    renderBase
      .get(`/api/v1/wcb-render/wallet/transactions?pageSize=10&page=${page}`)
      .then(res => res.data),
};

export { walletApi };
