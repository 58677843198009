import React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { appApi } from '../api/app';

const Context = createContext();

export const ConfigsProvider = ({ children }) => {
  const [features, setFeatures] = useState(
  {
    inStoreOffersEnabled: false,
    referralProgramEnabled: false, 
    mergeWithAppEnabled: false,
    cashoutModalEnabled: false,
    showAppDownloadLink: false,
    phone: {
      length: null,
      pattern: null,
      prefex: null
    }
  }
  );
  useEffect(() => {
    appApi.getConfigs().then((data) => {
      setFeatures(data);
    })
  }, []);
  const exposed = { features };
  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useCustomConfigs = () => useContext(Context);
