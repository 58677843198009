import axios from 'axios';
import { AppConfig } from '../config/config';

const lang = localStorage.getItem('i18nextLng');
const currentLang = AppConfig.get('LANGUAGES').find(el => el.code === lang);
const defaultLang = AppConfig.get('LANGUAGES').find(
  el => el.code === AppConfig.get('DEFAULT_LANG'),
);

const backEndBaseMS = axios.create({
  baseURL: `${window.env.REACT_APP_MS_GATEWAY}/api/v1/wcb-render/`,
  headers: {
    common: {
      language: currentLang?.id || defaultLang?.id,
      'Accept-Language': lang,
      'x-accept-Language': lang,
      'x-accept-country': AppConfig.get('COUNTRY'),
    },
  },
});

backEndBaseMS.interceptors.request.use(request => {
  request.headers['Authorization'] = localStorage.getItem('Token')
    ? 'Bearer ' + localStorage.getItem('Token')
    : null;
  return request;
});

export default backEndBaseMS;
