import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { combineReducers, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { ToastProvider } from "react-toast-notifications";
import GTMTracker from "./helpers/shared/gtm-tracker";
import { ConfigsProvider } from '../src/config/config.context';

// tanslation
import "./i18n";

import {
  appReducer,
  authReducer,
  accountReducer,
  cashoutReducer,
  walletReducer,
  newsletterReducer,
} from "./reducers";
import {
  TAppState,
  TUserState,
  TOnlineCashbacksState,
  TInStoreOffersState,
  TAccountState,
  TCashoutState,
  TWalletState,
  TConfigState,
  TNewsletterState,
} from "./types";
import Routing from "./components/Routing";

//JS
import "bootstrap/dist/js/bootstrap.bundle.min";

//CSS FILES
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import "mdbreact/dist/css/mdb.css";

//CSS CUSTOM FILES
import "./assets/scss/mdb.scss";
import { appApi } from "./api/app";
// import "./index.css";

export type TGlobalState = {
  configModule: TConfigState;
  appModule: TAppState;
  authModule: TUserState;
  onlineCashbacksModule: TOnlineCashbacksState;
  inStoreOffersModule: TInStoreOffersState;
  accountModule: TAccountState;
  cashoutModule: TCashoutState;
  walletModule: TWalletState;
  newsletterModule: TNewsletterState;
};

const rootReducer = combineReducers({
  appModule: appReducer,
  authModule: authReducer,
  accountModule: accountReducer,
  cashoutModule: cashoutReducer,
  walletModule: walletReducer,
  newsletterModule: newsletterReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware()
    // other store enhancers if any
  )
);

GTMTracker.initialize();

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={window.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ConfigsProvider>
        <ToastProvider>
          <Routing />
        </ToastProvider>
      </ConfigsProvider>
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById("root")
);



