import backEndBaseMS from './backEndBaseMS';
import renderBase from './renderBase';

import {} from '../types';

const accountApi = {
  sendOTPMorocco: (req: any) =>
    backEndBaseMS
      .post(
        `/api/v1/payment/affiliate/wallet/init?phone=212${req.mobileNumber}`,
      )
      .then(res => {
        return res.data;
      }),

  resendOTPMorocco: (mobileNumber: any) =>
    backEndBaseMS
      .post(`/api/v1/payment/affiliate/wallet/init?phone=212${mobileNumber}`)
      .then(res => {
        return res.data;
      }),

  verifyOTPMorocco: (req: any) =>
    backEndBaseMS
      .post(`/api/v1/payment/affiliate/wallet`, {
        phoneNumber: '212' + req.mobileNumber,
        otp: req.otp,
      })
      .then(res => {
        return res.data;
      }),
  UpdateProfile: (name: any) =>
    renderBase
      .put('/api/v1/wcb-render/actions/account/profile', {
        fullName: name,
      })
      .then(res => res.data),
  ChangePassword: (req: any) =>
    renderBase
      .put('/api/v1/wcb-render/oauth/password', {
        oldPassword: req.oldPassword,
        newPassword: req.newPassword,
        confirmPassword: req.confirmPassword,
      })
      .then(res => res.data),
};

export { accountApi };
