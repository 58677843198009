import EgyptConfig from './egyptConfig';
import MoroccoConfig from './moroccoConfig';

const ACCEPTED_LANGUAGES = [
  { id: 1, name: 'English', code: 'en' },
  { id: 2, name: 'Arabic', code: 'ar' },
  { id: 3, name: 'French', code: 'fr' },
  { id: 4, name: 'Arabic', code: 'ar-MA' },
];

const contriesList = {
  EGYPT: 'Egypt',
  MOROCCO: 'Morocco',
};
const supportedLangs = window.env.REACT_APP_APP_LANGUAGES.trim().split(',');

export class AppConfig {
  static state = {
    // genearal
    LANGUAGES: [
      ...ACCEPTED_LANGUAGES.filter(el => supportedLangs.includes(el.code)),
    ],
    DEFAULT_LANG: window.env.REACT_APP_APP_DEFAULT_LANG,
    COUNTRIES: {
      ...contriesList,
    },
    CONTACTUS_EMAIL: window.env.REACT_APP_CONTACTUS,
    RECAPTCHA_KEY: window.env.REACT_APP_RECAPTCHA_KEY,
    COUNTRY: window.env.REACT_APP_COUNTRY,
    ENV: window.env.REACT_APP_ENV,

    // social media links
    SOCIAL: {
      FACEBOOK: window.env.REACT_APP_FACEBOOK_URL,
      IMAGE_URL: window.env.REACT_APP_IMAGE_URL,
      INSTAGRAM: window.env.REACT_APP_INSTAGRAM_URL,
      LINKEDIN: window.env.REACT_APP_LINKEDIN_URL,
      TIKTOK: window.env.REACT_APP_TIKTOK_URL,
      YOUTUBE: window.env.REACT_APP_YOUTUBE_URL,
    },

    // cashout settings
    CASHOUT: {
      TOTAL_AMOUNT_PER_MONTH: window.env.REACT_APP_TOTAL_AMOUNT_PER_MONTH,
      BANK_PROCESSING_TIME: window.env.REACT_APP_BANK_PROCESSING_TIME,
      VOUCHER_PROCESSING_TIME: window.env.REACT_APP_VOUCHER_PROCESSING_TIME,
    },
  };

  static getCountryConfigs() {
    switch (this.state.COUNTRY) {
      case contriesList.EGYPT:
        return EgyptConfig;
      case contriesList.MOROCCO:
        return MoroccoConfig;
      default:
        return EgyptConfig;
    }
  }

  static setState(configs) {
    this.state = { ...configs };
  }

  static getState() {
    return this.state;
  }

  static get(key) {
    return this.state[key];
  }

  static getPhonePlaceholder() {
    return this.getCountryConfigs().phonePlaceHolder;
  }

  static getPhoneValidation() {
    return this.getCountryConfigs().phoneValidation;
  }

  static isZendeskAvailable() {
    return this.getCountryConfigs().isZendeskEnabled;
  }

  static cashPlaceholder() {
    return window.env.REACT_APP_CURRENCY;
  }
}
